<template>
  <div class="service-times-presentations template-2">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcCampInformation }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </header>
    <!-- / Page Header -->
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ campProfile.organization && campProfile.organization.org_name }}</h4>
          </header>
          <div class="d-flex row mb-2 details2">
            <div class="col-lg-6 col-sm-10">
              <div class="detail">
                <strong>{{ translations.tcEmail }}: </strong>
                <span>{{ campProfile.organization && campProfile.organization.eml_address }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcAddressLine1 }}: </strong>
                <span>{{ address.address_line1 }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcAddressLine2 }}: </strong>
                <span>{{ address.address_line2 }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcCity }}: </strong>
                <span>{{ address.city }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcState }}: </strong>
                <span>{{ address.state_name }}({{ address.state }})</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcPostalCode }}: </strong>
                <span>{{ address.postal_code }}</span>
              </div>

              <div class="detail">
                <strong>{{ translations.tcCountry }}: </strong>
                <span>{{ address.country }}</span>
              </div>
            </div>
            <div class="col-lg-6 col-sm-8">
              <div class="detail">
                <strong>{{ translations.tcPhone }}: </strong>
                <div>
                  <span>{{ translations.tcPhoneNumber }}: {{ phone.phone_number ? phone.phone_number : '' }}</span>
                  <span>{{ translations.tcExtension }}: {{ phone.phone_number ? phone.extension : '' }}</span>
                </div>
              </div>
              <div class="detail">
                <strong>{{ translations.tcFax }}: </strong>
                <span>{{ translations.tcFaxNumber }}: {{ fax.fax_number ? fax.fax_number : '' }}</span>
                <span>{{ translations.tcExtension }}: {{ fax.fax_number ? fax.extension : '' }}</span>
              </div>
            </div>
          </div>

          <b-button v-if="toggleMe" variant="primary" @click="handleEditClick" class="">
            {{ translations.tcEdit }}
          </b-button>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg mt-5">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-profile-cm',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/officers/camp/camp-officers-home',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      getCampProfile: 'campModule/getCampProfile',
      setFormattedProfile: 'campModule/setFormattedProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getViewTranslations(),
          this.getComponentTranslations('back-jump'),
          await this.getCampProfile(),
        ]).then(results => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
    async handleEditClick() {
      await this.setFormattedProfile(this.formattedProfile)
      this.$router.push({ name: 'edit-camp-profile-cm' })
    },
  },
  computed: {
    ...mapGetters({
      campProfile: 'campModule/campProfile',
      prefCulture: 'user/userPreferredCulture',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    email() {
      if (!this.campProfile.emails || this.campProfile.emails.length <= 0) {
        return {}
      }
      return this.campProfile.emails[0]
    },
    address() {
      if (!this.campProfile.addresses || this.campProfile.addresses.length <= 0) {
        return {}
      }
      return this.campProfile.addresses[0]
    },
    phone() {
      if (!this.campProfile.phones || this.campProfile.phones.length <= 0) {
        return {}
      }
      return this.campProfile.phones[0]
    },
    fax() {
      if (!this.campProfile.faxes || this.campProfile.faxes.length <= 0) {
        return {}
      }
      return this.campProfile.faxes.find((fax) => fax.primary_fax_flag == 1) || {}
    },
    formattedProfile() {
      let profile = {
        organization: this.campProfile.organization,
        address: this.address,
        email: this.email,
        fax: this.fax,
        phone: this.phone,
      }
      return profile
    },
  },
  async created() {
    await this.pageLoad()
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    iTooltip: iTooltip,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details2 {
  margin-bottom: 50px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .detail {
    font-size: 15px;
    margin-bottom: 10px;
    display: flex;
    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
      line-height: 28px;
    }
    @media (min-width: 360px) and (max-width: 640px) {
      display: block;
    }
    strong {
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 210px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }
    &.full-detail {
      display: block;
    }
  }
}
</style>
